import React from "react"
import window from "global"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import Fade from 'react-reveal/Fade';
import scrollTo from 'gatsby-plugin-smoothscroll';

function Activities() {

  const data = useStaticQuery(graphql`
   query activitiesQuery {
       BackgroundImage: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Background.png" }) {
           childImageSharp {
             fluid(maxWidth: 1920) {
               ...GatsbyImageSharpFluid_withWebp_noBase64
             }
          }
       }
       iconRoof: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Dakwerkzaamheden.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      iconRoller: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Schilderwerk.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      iconStuc: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Stucwerk.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      iconHammer: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Timmerwerkzaamheden.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      iconHand: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Hand.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      iconHome: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Huis.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      iconTalk: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Deskundig advies.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      iconCheck: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/Vinkje.png" }) {
        childImageSharp {
          fixed(width: 20) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      leftDak: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/IMG_Dakwerkzaamheden.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rightSchilder: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/IMG_Schilderwerk.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      leftStuc: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/IMG_Stucwerk.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rightTimmer: file(relativePath: { eq: "WERKZAAMHEDEN/LOSSE AFBEELDINGEN/IMG_Timmerwerk.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [hasMounted, setHasMounted] = React.useState(false)
  const [width, setWidth] = React.useState(window.innerWidth)
  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  React.useEffect(() => {
    setHasMounted(true)
  }, [width])

  if (!hasMounted) {
    return null
  }

  let coverText = (
    <Col
      className={"head-text"}
      style={{ color: "white", marginTop: "25vh" }}
      md={{ span: 8, offset: 2 }}>
      <Fade top delay="500" duration="2000">
        <h3>Werkzaamheden</h3>
      </Fade>
      <Fade left duration="2000">
        <h1 style={{ fontSize: "4em" }}>
          Alders Vastgoedonderhoud,<br></br> wij zijn trots op wat we maken
        </h1>
      </Fade>
      <Fade bottom delay="750" duration="1500">
        <h2 className={"normal-text"} style={{ fontSize: "2em" }}>
          Een soepele verbouwing met fijne communicatie en een eindresultaat waar u nog heel lang plezier van heeft. Dat is ook ons doel.
        </h2>
      </Fade>
    </Col>
  )

  if (width <= 1250) {
    coverText = (
      <Col
        className={"head-text"}
        style={{ color: "white", marginTop: "-77vh", zIndex: -1 }}
        md={{ span: 8, offset: 2 }}>
        <Fade top delay="500" duration="2000">
          <h5>Werkzaamheden</h5>
        </Fade>
        <Fade left duration="2000">
          <h3>
            Alders Vastgoed-onderhoud,<br></br> wij zijn trots op wat we maken
          </h3>
        </Fade>
        <Fade bottom delay="750" duration="1500">
          <h4 className={"normal-text"}>
            Een soepele verbouwing met fijne communicatie en een eindresultaat waar u nog heel lang plezier van heeft. Dat is ook ons doel.
          </h4>
        </Fade>
      </Col>
    )
  }

  return (
    <>
      <SEO title="Werkzaamheden" />
      <Layout width={width} backgroundImage={data.BackgroundImage.childImageSharp.fluid} coverText={coverText} backgroundImageStyles={{backgroundColor: "black", backgroundPosition: "center", height: "100vh"}}>
      <Container fluid className="mb-5">
          <Row>
            <Col className="head-text" style={{color: "#1F2D53", textAlign: "center", marginTop: "5%"}}>
            <h2>HEEFT U EEN PROJECT WAARBIJ WE KUNNEN HELPEN?</h2>
            <br></br>
            <h5>Wij hebben ruime ervaring en streven naar een optimaal eindresultaat.</h5>
            </Col>
          </Row>
          <Row className="normal-text my-5" style={{color: "#1F2D53", textAlign: "center", justifyContent: "space-between", margin: "0 5% 0 5%"}}>
            <Col onClick={() => scrollTo('#dakwerkzaamheden')} xs={12} xl={3} style={{color: "#1F2D53", textDecoration: "none"}} className="mt-0 mt-xl-5 scaleUpHover">
              <Fade left>
                <Image className="mb-3" fixed={data.iconRoof.childImageSharp.fixed}/>
                <p className="head-text">Dakwerkzaamheden</p>
                <p>Dakpannen, dakisolatie,<br></br> bitumen dakbedekking</p>
              </Fade>
            </Col>
            <Col onClick={() => scrollTo('#schilderwerk')} xs={12} xl={3} style={{color: "#1F2D53", textDecoration: "none"}} className="mt-5 scaleUpHover">
              <Fade bottom>
                <Image className="mb-3" fixed={data.iconRoller.childImageSharp.fixed}/>
                <p className="head-text">Schilderwerk</p>
                <p>Binnen- en buitenschilderwerk,<br></br> airless spuiten, houtrot herstel</p>
              </Fade>
            </Col>
            <Col onClick={() => scrollTo('#stucwerk')} xs={12} xl={3} style={{color: "#1F2D53", textDecoration: "none"}} className="mt-5 scaleUpHover">
              <Fade bottom>
                <Image className="mb-3 mr-n4" fixed={data.iconStuc.childImageSharp.fixed}/>
                <p className="head-text">Stucwerk</p>
                <p>Binnen- en buitenstucwerk,<br></br> sierpleisterwerk</p>
              </Fade>
            </Col>
            <Col onClick={() => scrollTo('#timmerwerkzaamheden')} xs={12} xl={3} style={{color: "#1F2D53", textDecoration: "none"}} className="mt-5 scaleUpHover">
              <Fade right>
                <Image className="mb-3 mr-n4" fixed={data.iconHammer.childImageSharp.fixed}/>
                <p className="head-text">Timmerwerkzaamheden</p>
                <p>Kleine- en grote timmerwerkzaamheden,<br></br> veranda, tuinhuis, carport</p>
              </Fade>
            </Col>
          </Row>
        </Container>
        <Container fluid className="py-5 pl-5 normal-text" style={{backgroundColor: "#1F2D53", color: "white"}}>
          <Row>
            <Col className="p-3 p-sm-5" xs={12} lg={4}>
              <Fade left>
                <Row>
                  <Col xs={3}>
                    <Image fixed={data.iconTalk.childImageSharp.fixed}/>
                  </Col>
                  <Col xs={8}>
                    <p>We luisten naar uw wensen en behoeften en geven een deskundig advies op maat.</p>
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col className="p-3 p-sm-5" xs={12} lg={4}>
              <Fade bottom>
                <Row>
                  <Col xs={3}>
                    <Image fixed={data.iconHome.childImageSharp.fixed}/>
                  </Col>
                  <Col xs={8}>
                    <p>We gaan zorgvuldig om met uw huis en spullen en laten alles netjes achter.</p>
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col className="p-3 p-sm-5" xs={12} lg={4}>
              <Fade right>
                <Row>
                  <Col xs={3}>
                    <Image fixed={data.iconHand.childImageSharp.fixed}/>
                  </Col>
                  <Col xs={8}>
                    <p>Wij streven niet alleen naar een prettige samenwerking, maar ook naar een mooi eindresultaat.</p>
                  </Col>
                </Row> 
              </Fade>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
          <Col className="px-0" xs={12} xl={6}>
                <Image className="h-100" fluid={data.leftDak.childImageSharp.fluid}/>
            </Col>
            <Col xs={12} xl={6} id="dakwerkzaamheden" className="px-5 py-5" style={{margin: "auto"}}>
              <Fade right>
                <h2 className="head-text" style={{color: "#1F2D53"}}>
                  DAKWERKZAAM&shy;HEDEN
                </h2>
                <p className="normal-text">
                  Voor verschillende soorten dakbedekking bent u bij ons aan het juiste adres.
                  Het soort dakbedekking is erg afhankelijk van het type dak. Sommige types, zoals bitumen en EPDM zijn geschikt voor een plat dak.
                  Voor een schuin dak worden vaak dakpannen gekozen.
                  Voor de meeste dakpannen geldt dat dakpannen gelegd kunnen worden vanaf een helling van minimaal 25 graden.<br></br><br></br>
                  Dakisolatie is een belangrijk onderdeel van het dak. Een goed geïsoleerd dak zorgt voor een lagere energierekening.
                  Een slecht geïsoleerd dak verliest tot wel 30% aan warmte in huis.
                </p>
                <ul className="normal-text" style={{listStyleType: "none"}}>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Hellend dak</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Plat dak</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Dakisolatie</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Dakpannen</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Bitumen dakbedekking</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Kilgoten</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Regenpijpen</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Lood vernieuwen dakkapel</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Dakgoot renovatie</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Schoorsteen renovatie</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Lekkage dak</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Stormschade</li>
                </ul>
                <Link to="/quotation/" rel="nofollow" className={"head-text no-underline mt-3 btn"} style={{minWidth: "300px", backgroundColor:"#182F55", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0, color: "white"}}>OFFERTE AANVRAGEN</Link>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col style={{backgroundColor: "#CBBAA0", height: "75px"}}>
            </Col>
          </Row>
          <Row>
            <Col id="schilderwerk" xs={{span: 12, order: 1}} xl={{span: 6, order: 0}} className="px-5 py-5" style={{margin: "auto", textAlign: "right"}}>
              <Fade left>
                <h2 className="head-text" style={{color: "#1F2D53"}}>
                  SCHILDERWERK
                </h2>
                <p className="normal-text">
                  Het uitvoeren van goed binnenschilderwerk is een vak apart.
                  Het verfraaien, in ere herstellen en onderhouden van woningen, kantoren en gebouwen is onze specialiteit.
                  Duurzame kwaliteit vinden we erg belangrijk. De verfproducten worden per situatie zorgvuldig geselecteerd,
                  zodat u jarenlang kunt genieten van een stralende woning.
                  Ook de voorbereiding, zoals het opschuren van de ondergrond, wordt zorgvuldig gedaan, waardoor de verf extra lang mooi blijft.
                  Wij verzien u graag van persoonlijk advies en geven u tips over hoe u het schilderwerk het beste kunt onderhouden.
                </p>
                <ul className="normal-text" style={{listStyleType: "none"}}>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Onderhoud<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Nieuwbouw<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Renovatie<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Herstel van binnen- en buitenschilderwerk<Image className="ml-3 pl-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Afkitten<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Texwerken<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Advies over kleuren en materialen<Image className="ml-3 pl-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Houtrot herstel<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Airless spuiten<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                </ul>
                <Link to="/quotation/" rel="nofollow" className={"head-text no-underline mt-3 btn"} style={{minWidth: "300px", backgroundColor:"#182F55", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0, color: "white"}}>OFFERTE AANVRAGEN</Link>
              </Fade>
            </Col>
            <Col className="px-0" xs={{span: 12, order: 0}} xl={{span: 6, order: 1}}>
                <Image className="h-100" fluid={data.rightSchilder.childImageSharp.fluid}/>
            </Col>
          </Row>
          <Row>
            <Col style={{backgroundColor: "#CBBAA0", height: "75px"}}>
            </Col>
          </Row>
          <Row>
          <Col className="px-0" xs={12} xl={6}>
                <Image className="h-100" fluid={data.leftStuc.childImageSharp.fluid}/>
            </Col>
            <Col id="stucwerk" xs={12} xl={6} className="px-5 py-5" style={{margin: "auto"}}>
              <Fade right>
                <h2 className="head-text" style={{color: "#1F2D53"}}>
                  STUCWERK
                </h2>
                <p className="normal-text">
                  Mooi stucwerk kan uw huis compleet veranderen.
                  Strucwerk is er in verschillende vormen te krijgen met ieder zijn eigen stijl en eigenschappen.
                  Niet alleen het uiterlijk, maar ook de structuur is bepalend.
                  Daarnaast heeft de plek waar het stucwerk op wordt aangebracht, ook invloed op de keuze.
                  Voor iedere situatie is er een andere type geschikt.<br></br><br></br>
                  Wat niet veel mensen weten, is dat gestucte muren en plafonds brandvertragend werken.
                </p>
                <ul className="normal-text" style={{listStyleType: "none"}}>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Spackspuiten</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Sierpleister</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Glad stucwerk</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Saus- en behangklaar</li>
                  <li><Image className="mr-3 ml-n4" fixed={data.iconCheck.childImageSharp.fixed}/>Beton Ciré</li>
                </ul>
                <Link to="/quotation/" rel="nofollow" className={"head-text no-underline mt-3 btn"} style={{minWidth: "300px", backgroundColor:"#182F55", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0, color: "white"}}>OFFERTE AANVRAGEN</Link>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col style={{backgroundColor: "#CBBAA0", height: "75px"}}>
            </Col>
          </Row>
          <Row>
            <Col id="timmerwerkzaamheden" xs={{span: 12, order: 1}} xl={{span: 6, order: 0}} className="px-5 py-5" style={{margin: "auto", textAlign: "right"}}>
              <Fade left>
                <h2 className="head-text" style={{color: "#1F2D53"}}>
                  TIMMERWERK
                </h2>
                <p className="normal-text">
                  Alders Vastgoedonderhoud monteert, repareert allerlei dingen van hout.
                  Naast diverse klussen voor in en rondom uw woning, kantoor of gebouw kunt u ons ook inschakelen voor maatwerk.
                  Graag bespreken we uw wensen persoonlijk met u door en kunnen op basis van uw wensen en behoeften een vrijblijvende offerte maken.
                </p>
                <ul className="normal-text ml-n5" style={{listStyleType: "none"}}>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Aanbouw<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Dakkapellen<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Kapconstructies<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Stelwerk van ramen<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Deuren en kozijnen<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                  <li className="d-flex" style={{justifyContent: "flex-end"}}>Aftimmerwerkzaamheden<Image className="ml-3" fixed={data.iconCheck.childImageSharp.fixed}/></li>
                </ul>
                <Link to="/quotation/" rel="nofollow" className={"head-text no-underline mt-3 btn"} style={{minWidth: "300px", backgroundColor:"#182F55", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0, color: "white"}}>OFFERTE AANVRAGEN</Link>
              </Fade>
            </Col>
            <Col className="px-0" xs={{span: 12, order: 0}} xl={{span: 6, order: 1}}>
                <Image className="h-100" fluid={data.rightTimmer.childImageSharp.fluid}/>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Activities
